import Button from '/components/Button';
import Clickable from '/components/Clickable';
import FilterPanel from '/components/collections/FilterPanel';
import Pagination from '/components/Pagination';
import ProductCard from '/components/product-card/ProductCard';
import ProductFacets from '/components/collections/ProductFacets';
import PropTypes from 'prop-types';
import { SORT_OPTIONS } from '/services/searchspring/constants';
import SelectGroup from '/components/SelectGroup';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getTestVariant } from '/services/ab-test';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '/state/global-context';
import { useRouter } from 'next/router';
import { trackSearchPage, trackViewItemList } from '/services/analytics/shinesty-analytics';
import { useEffect, useState } from 'react';

const ProductListingSS = ({ collectionHandle, searchspringResponse, productCardClassNames }) => {
	const globalStore = useGlobalStore();
	const router = useRouter();
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [filterPanelOpen, setFilterPanelOpen] = useState(false);
	const [testVariant, setTestVariant] = useState(null);
	const products = searchspringResponse?.results || [];
	const formattedProducts = products;
	const formattedFacets = searchspringResponse?.facets;

	const onFacetChange = (facetGroupKey, facetTitle, isChecked) => {
		const newQuery = { ...router.query };
		let filters = {};
		try {
			filters = newQuery.selectedFacets ? JSON.parse(newQuery.selectedFacets) : {};
		} catch (e) {
			console.error('Error parsing selectedFacets:', e);
			filters = {};
		}

		if (isChecked) {
			filters[facetGroupKey] = filters[facetGroupKey] || [];
			if (!filters[facetGroupKey].includes(facetTitle)) {
				filters[facetGroupKey].push(facetTitle);
			}
		} else {
			if (filters[facetGroupKey]) {
				filters[facetGroupKey] = filters[facetGroupKey].filter((f) => f !== facetTitle);
				if (filters[facetGroupKey].length === 0) {
					delete filters[facetGroupKey];
				}
			}
		}

		newQuery.selectedFacets = JSON.stringify(filters);
		newQuery.page = 1;

		router.push({
			pathname: router.pathname,
			query: newQuery,
		});
	};

	useEffect(() => {
		if (router.query.q) {
			trackSearchPage(products, router.query.q);
		} else {
			trackViewItemList(products, collectionHandle);
		}

		const fetchTest = async () => {
			const test = await getTestVariant('athletic-leader');
			setTestVariant(test.key);
		};

		if (collectionHandle === 'ball-hammock-athletic-shorts-1') {
			fetchTest();
		}
	}, []);

	const onSortChange = (value) => {
		router.push({
			pathname: router.pathname,
			query: {
				...router.query,
				sort: value,
				page: 1,
			},
		});
	};

	return (
		<div className="flex flex-col">
			<div
				className={classNames(
					'sticky lg:hidden uppercase w-full z-20 text-center mb-4 p-2 bg-v2-off-white',
					globalStore.topPositionPageMobile,
				)}>
				<Clickable
					className="w-full py-3 text-gray-600"
					variant="round-outlined"
					color="gray-600"
					onClick={() => setFilterPanelOpen(!filterPanelOpen)}>
					<Typography variant="heading-sm">Sort &amp; Filter</Typography>
				</Clickable>
			</div>

			<div
				className={classNames(
					'hidden lg:flex flex-col sticky bg-v2-off-white z-20 justify-between py-2 md:flex-row-reverse',
					globalStore.topPositionPage,
				)}>
				<div className="mt-1.5">
					<div className="w-72">
						<span className="bg-v2-off-white px-1 absolute top-1 right-60 h-4 text-[13px] z-20">
							Sort by
						</span>
						<SelectGroup
							defaultValue={router.query.sort || 'most relevant'}
							name="Sort By"
							onChange={onSortChange}
							options={Object.keys(SORT_OPTIONS).map((key) => ({
								value: key,
								label: key,
							}))}
							placeholder="Sort By"
							variant="medium"
						/>
					</div>
				</div>

				<div className="w-64 flex-none">
					{filtersVisible ? (
						<Button
							onClick={() => setFiltersVisible(false)}
							title="hide filter options"
							type="button"
							variant="naked">
							Hide Filters
						</Button>
					) : (
						<Clickable
							onClick={() => setFiltersVisible(true)}
							title="show filter options"
							type="button"
							variant="naked">
							Show Filters
						</Clickable>
					)}
				</div>
			</div>

			<div className="flex flex-col relative md:flex-row pt-3">
				{filtersVisible && (
					<div className="hidden lg:block flex-none w-72">
						<div className={classNames('sticky', globalStore.topPositionFacets)}>
							<div className="h-[90vh] overflow-scroll scrollbar-hide">
								<ProductFacets
									facetGroups={formattedFacets}
									onFacetChange={onFacetChange}
									onFacetClear={() => {
										router.push({
											pathname: router.pathname,
											query: {
												...router.query,
												selectedFacets: undefined,
												page: 1,
											},
										});
									}}
									showSelectedFacetPills={true}
								/>
							</div>
						</div>
					</div>
				)}

				<ul className="mx-4 sm:mx-6 grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0">
					{formattedProducts.map((product, i) => (
						<ProductCard
							key={i}
							className={productCardClassNames}
							priority={i < 5}
							product={product}
							testVariant={testVariant}
							rank={i}
						/>
					))}
				</ul>
			</div>

			<div className="my-4">
				<Pagination
					numberOfPages={searchspringResponse?.pagination?.totalPages}
					onChange={(val) => {
						router.push({
							pathname: router.pathname,
							query: { ...router.query, page: val },
						});
					}}
					page={searchspringResponse?.pagination?.currentPage}
				/>
			</div>

			<FilterPanel
				closeCallback={() => setFilterPanelOpen(false)}
				facetGroups={formattedFacets}
				isOpen={filterPanelOpen}
				onFacetChange={onFacetChange}
				onSortChange={onSortChange}
				sort={router.query.sort}
			/>
		</div>
	);
};

ProductListingSS.propTypes = {
	collectionHandle: PropTypes.string,
	productCardClassNames: PropTypes.string,
	searchspringResponse: PropTypes.object,
};

export default observer(ProductListingSS);
